<script>
/**
 * Right sidebar component
 */
import { layoutMethods } from "@/state/helpers";
export default {
  components: {},
  data() {
    return {
      config: {
        handler: this.handleRightBarClick,
        middleware: this.middleware,
        events: ["click"]
      }
    };
  },

  methods: {
    hide() {
      this.$parent.toggleRightSidebar();
    },
    // eslint-disable-next-line no-unused-vars
    handleRightBarClick(e, el) {
      this.$parent.hideRightSidebar();
    },
    // eslint-disable-next-line no-unused-vars
    middleware(event, el) {
      return !event.target.classList.contains("toggle-right");
    },
    darkThemeSwitch() {
      
    },
    ...layoutMethods,
    changeLayout(layout) {
      this.changeLayoutType({ layoutType: layout });
    },
    compactSidebar() {
      document.body.setAttribute("data-sidebar-size", "small");
      document.body.classList.remove("vertical-collpsed");
      document.body.removeAttribute("data-keep-enlarged", "true");
      document.body.removeAttribute("data-layout-size", "boxed");
    },
    iconSidebar() {
      document.body.classList.add("vertical-collpsed");
      document.body.setAttribute("data-keep-enlarged", "true");
      document.body.removeAttribute("data-sidebar-size", "small");
      document.body.removeAttribute("data-layout-size", "boxed");
    },
    boxedLayout() {
      document.body.setAttribute("data-layout-size", "boxed");
      document.body.classList.add("vertical-collpsed");
      document.body.setAttribute("data-keep-enlarged", "true");
    },
    lightTheme() {
      this.$vuetify.theme.darkThemeSwitch
    }
  }
};
</script>

<template>
  <div>
    <div v-click-outside="config" class="right-bar">
      <div data-simplebar class="h-100">
        <div class="rightbar-title px-3 py-4">
          <a href="javascript:void(0);" class="right-bar-toggle float-right" @click="hide">
            <i class="far fa-times noti-icon"></i>
          </a>
          <h5 class="m-0">{{ $t('settings.settings') }}</h5>
        </div>

        <!-- Settings -->
        <hr class="mt-0" />
        <h6 class="text-center">{{ $t('settings.chooselayout') }}</h6>

        <div class="p-4">
          <div class="mb-2">
            <a @click="changeLayout('horizontal')" href="javascript: void(0);">
              <img src="@/assets/images/layouts/layout-1.jpg" class="img-fluid img-thumbnail" alt />
            </a>
          </div>

          <div class="mb-2">
            <a @click="changeLayout('vertical')" href="javascript: void(0);">
              <img src="@/assets/images/layouts/layout-2.jpg" class="img-fluid img-thumbnail" alt />
            </a>
          </div>

          <div class="mb-2">
            <a href="javascript:void(0);" @click="boxedLayout()">Boxed Layout</a>
          </div>

          <div class="mb-2">
            <a href="javascript:void(0);" @click="compactSidebar()">Compact Sidebar</a>
          </div>

          <div class="mb-2">
            <a href="javascript:void(0);" @click="iconSidebar()">Icon Sidebar</a>
          </div>

          <div class="mb-2">
            <a href="javascript:void(0);" @click="lightTheme()">Theme Switch</a>
          </div>
        </div>
      </div>
      <!-- end scroll-->
    </div>

    <!-- Right bar overlay-->
    <div class="rightbar-overlay"></div>
  </div>
</template>
<style lang="scss"></style>