<script>
import { layoutMethods } from "@/state/helpers";

/**
 * Horizontal navbar component
 */
export default {
  mounted() {
    var links = document.getElementsByClassName("side-nav-link");
    var matchingMenuItem = null;
    for (var i = 0; i < links.length; i++) {
      if (window.location.pathname === links[i].pathname) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add("active");
        const parent2 = parent.parentElement;
        if (parent2) {
          parent2.classList.add("active");
        }
        const parent3 = parent2.parentElement;
        if (parent3) {
          parent3.classList.add("active");
          var childAnchor = parent3.querySelector(".has-dropdown");
          if (childAnchor) childAnchor.classList.add("active");
        }

        const parent4 = parent3.parentElement;
        if (parent4) parent4.classList.add("active");
        const parent5 = parent4.parentElement;
        if (parent5) parent5.classList.add("active");
      }
    }
  },
  methods: {
    ...layoutMethods,

    /**
     * menu clicked show the subelement
     */
    onMenuClick(event) {
      event.preventDefault();
      const nextEl = event.target.nextSibling;
      if (nextEl && !nextEl.classList.contains("show")) {
        const parentEl = event.target.parentNode;
        if (parentEl) {
          parentEl.classList.remove("show");
        }
        nextEl.classList.add("show");
      } else if (nextEl) {
        nextEl.classList.remove("show");
      }
      return false;
    },
    topbarLight() {
      document.body.setAttribute("data-topbar", "light");
      document.body.removeAttribute("data-layout-size", "boxed");
    },
    boxedWidth() {
      document.body.setAttribute("data-layout-size", "boxed");
      document.body.removeAttribute("data-topbar", "light");
      document.body.setAttribute("data-topbar", "dark");
    }
  }
};
</script>
<template>
  <div class="topnav">
    <div class="container-fluid">
      <nav class="navbar navbar-light navbar-expand-lg topnav-menu">
        <div class="collapse navbar-collapse" id="topnav-menu-content">
          <ul class="navbar-nav">
            <li class="nav-item">
              <router-link tag="a" class="nav-link side-nav-link" to="/">
                <i class="fas fa-home mr-2"></i>Home
              </router-link>
            </li>

            <li class="nav-item">
              <router-link tag="a" class="nav-link side-nav-link" to="/search">
                <i class="fas fa-search mr-2"></i>Search
              </router-link>
            </li>

            <li clss="nav-item">
                <router-link tag="a" class="nav-link side-nav-link" to="/db">
                  <i class="fas fa-book mr-2"></i>Database
                </router-link>
            </li>

            <li class="nav-item dropdown mega-dropdown">
              <a
                class="nav-link dropdown-toggle arrow-none"
                href="javascript: void(0);"
                id="topnav-uielement"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                @click="onMenuClick"
              >
                <i class="fas fa-user-friends mr-2"></i>Characters
              </a>

              <div class="dropdown-menu mega-dropdown-menu" aria-labelledby="topnav-uielement">
                <div class="row">
                  <div class="col-lg-4">
                    <div>
                      <router-link to="/character/9065567" class="dropdown-item side-nav-link">Syntafin Konpaku</router-link>
                      <router-link to="/character/9077911" class="dropdown-item side-nav-link">Miya Nono</router-link>
                      <router-link to="/character/14311968" class="dropdown-item side-nav-link">Ava Scharu</router-link>
                      <router-link to="/character/5402076" class="dropdown-item side-nav-link">Spiswel Gwennardec</router-link>
                      <router-link to="/character/10297707" class="dropdown-item side-nav-link">Perseus Sae'rael</router-link>
                      <router-link to="/character/27509291" class="dropdown-item side-nav-link">Ciwi Banhi (Lich)</router-link>
                    </div>
                  </div>
                </div>
              </div>
            </li>

            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle arrow-none"
                href="javascript: void(0);"
                id="topnav-pages"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                @click="onMenuClick"
              >
                <i class="fas fa-building mr-2"></i>Free Company
              </a>
              <div class="dropdown-menu mega-dropdown dropdown-menu-left dropdown-mega-menu-lg" aria-labelledby="topnav-components">
                <div class="row">
                  <div class="col-lg-4">
                    <router-link to="/freecompany/9232801448574624851" class="dropdown-item">Double Spoiler</router-link>
                    <router-link to="/freecompany/9232801448574588341" class="dropdown-item">Blaue Rose</router-link>
                  </div>
                  <div class="col-lg-4">
                    <a href="/calendar" class="dropdown-item">Calendar</a>
                  </div>
                </div>
              </div>
            </li>
            
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle arrow-none"
                href="javascript: void(0);"
                id="topnav-layout"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                @click="onMenuClick"
              >
                <i class="fa fa-info-circle mr-2"></i> About
              </a>
              <div class="dropdown-menu" aria-labelledby="topnav-layout">
                <router-link tag="a" to="/about/team" class="dropdown-item side-nav-link">Team</router-link>
                <div class="dropdown-divider"></div>
                <router-link tag="a" to="/about/impress" class="dropdown-item side-nav-link">Impress</router-link>
              </div>
            </li>

          </ul>
        </div>
      </nav>
    </div>
  </div>
</template>