<script>

/**
 * Topbar component
 */
export default {
  data: () => ({ languages: ['en', 'de', 'fr', 'ja'] }),
  methods: {
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    changeLang (lang) {
      this.$i18n.locale = lang
      this.$cookies.set('language', lang)
    },
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    }
  }
};
</script>
<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <router-link tag="a" to="/" class="logo logo-dark">
            <span class="logo-sm">
              <img src="@/assets/images/logo-sm.png" alt height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-65.png" alt height="65" />
            </span>
          </router-link>

          <router-link tag="a" to="/" class="logo logo-light">
            <span class="logo-sm">
              <img src="@/assets/images/logo-sm.png" alt height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-65.png" alt height="65" />
            </span>
          </router-link>
        </div>

        <button
          type="button"
          class="btn btn-sm px-3 font-size-24 header-item"
          id="vertical-menu-btn"
          @click="toggleMenu()"
        >
          <i class="far fa-bars"></i>
        </button>

        <div class="d-none d-sm-block">
                  <!-- App Search-->
        <form class="app-search d-none d-lg-block">
          <div class="position-relative">
            <input type="text" class="form-control" placeholder="Search..." />
            <span class="fa fa-search"></span>
          </div>
        </form>
        </div>
      </div>

      <div class="d-flex">

        <div class="dropdown d-inline-block d-lg-none ml-2">
          <button
            type="button"
            class="btn header-item noti-icon"
            id="page-header-search-dropdown"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="mdi mdi-magnify"></i>
          </button>
          <div
            class="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
            aria-labelledby="page-header-search-dropdown"
          >
            <form class="p-3">
              <div class="form-group m-0">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search ..."
                    aria-label="Recipient's username"
                  />
                  <div class="input-group-append">
                    <button class="btn btn-primary" type="submit">
                      <i class="mdi mdi-magnify"></i>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <b-dropdown class="d-md-block ml-2" toggle-class="header-item" right variant="white">
          <template v-slot:button-content>
            <i class="far fa-language fa-2x mr-2"></i>
            <span class="fas fa-caret-down fa-2x"></span>
          </template>
          <a v-for="lang in languages" :key="lang" @click="changeLang(lang)" class="dropdown-item side-nav-link">{{ $t('languages.' +lang + '') }}</a>
        </b-dropdown>

        <div class="dropdown d-none d-lg-inline-block">
          <button type="button" class="btn header-item noti-icon" @click="initFullScreen">
            <i class="far fa-expand-wide"></i>
          </button>
        </div>

        <b-dropdown class="d-inline-block" right toggle-class="header-item" variant="white">
          <template v-slot:button-content>
            <img
              class="rounded-circle header-profile-user"
              src="@/assets/images/users/avatar-4.jpg"
              alt="User Avatar"
            />
          </template>
          <a class="dropdown-item" href="/login">
            <i class="far fa-sign-in font-size-17 align-middle mr-1"></i>{{ $t('user.login') }}
          </a>
          <a class="dropdown-item" href="/register">
            <i class="far fa-user-plus font-size-17 align-middle mr-1"></i>{{ $t('user.register') }}
          </a>  
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="#">
            <i class="far fa-user font-size-17 align-middle mr-1"></i>{{ $t('user.profile') }}
          </a>
          <a class="dropdown-item" href="#">
            <i class="far fa-inbox font-size-17 align-middle mr-1"></i>{{ $t('user.pm') }}
          </a>
          <a class="dropdown-item d-block" href="#">
            <i class="far fa-sliders-v-square font-size-17 align-middle mr-1"></i>{{ $t('user.settings') }}
          </a>
          <a class="dropdown-item" href="#">
            <i class="far fa-user-crown font-size-17 align-middle mr-1"></i>{{ $t('user.admin') }}
          </a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-danger" href="#">
            <i class="far fa-sign-out font-size-17 align-middle mr-1 text-danger"></i>{{ $t('user.logout') }}
          </a>
        </b-dropdown>

        <div class="dropdown d-inline-block">
          <button
            type="button"
            class="btn header-item noti-icon right-bar-toggle toggle-right"
            @click="toggleRightSidebar"
          >
            <i class="far fa-cog toggle-right"></i>
          </button>
        </div>
      </div>
    </div>
  </header>
</template>